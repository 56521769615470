@tailwind base;
@tailwind components;
@tailwind utilities;

.a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}

/* TODO: this is range input styling that we should move to the component level */
/* webkit browsers */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply bg-slate-800 rounded-full cursor-pointer overflow-visible w-4 h-4;
}
/* Firefox */
input[type='range']:-moz-range-thumb {
  -moz-appearance: none;
  @apply bg-slate-800 rounded-full cursor-pointer overflow-visible w-4 h-4;
}

.overlapping-underline-xl {
  text-decoration-skip-ink: none;
  text-underline-offset: -4px;
}

.overlapping-underline {
  text-decoration-skip-ink: none;
  text-underline-offset: -2px;
}
